import {
  ssoErrorCodes,
  SSOErrorMessageDetails,
  SSOGenericErrorMessage,
  SupportLink,
} from '@mentimeter/sso-ui';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { P } from '@mentimeter/ragnar-ui/typography';
import type { ResponseError } from './types';

export default function SAMLError({
  error,
}: { error: ResponseError }): React.ReactNode {
  const requestId = error.requestId;
  switch (error.code) {
    case ssoErrorCodes.IDENTITY_PROVIDER_NOT_FOUND:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak">
            The workspace doesn&#39;t have SSO configured, use another sign in
            method or reach out to your IT-administrator.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.IDENTITY_PROVIDER_NOT_FOUND}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.INVALID_SAML_METADATA:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            There is a problem with the SSO configuration, and we are unable to
            sign you in. Reach out to your internal IT administrator for help.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.INVALID_SAML_METADATA}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.WORKSPACE_NOT_FOUND:
      const description = `${error.data?.query ?? 'The workspace'} is not registered. Double check the spelling or reach out to your IT-administrator for help.`;

      return (
        <CalloutCard
          theme="negative"
          description={description}
          actions={[
            {
              children: 'Get help finding workspace',
              key: 'get-help-finding-workspace',
              id: 'sso-intercom-trigger',
              variant: 'outline',
              href: '#',
            },
          ]}
        />
      );
    case ssoErrorCodes.DOMAIN_NOT_FOUND:
      return (
        <CalloutCard
          theme="negative"
          description="We couldn’t find a workspace with this email. Double check the spelling or try searching by the workspace’s name instead."
        />
      );
    case ssoErrorCodes.WORKSPACE_CONFLICT:
      return (
        <CalloutCard
          theme="negative"
          description="We found multiple workspaces from your email domain. Enter the name of the workspace that you’d like to join."
        />
      );
    case ssoErrorCodes.INVALID_SAML_RESPONSE:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            There is a problem with the SSO configuration, and we are unable to
            sign you in. Reach out to your internal IT administrator for help.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.INVALID_SAML_RESPONSE}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.INSUFFICIENT_WORKSPACE_PLAN:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            Your workspace does not support SSO. Use another sign in method to
            access Mentimeter, and ask your internal IT-administrator to be
            invited to the team.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.INSUFFICIENT_WORKSPACE_PLAN}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.LOGIN_TIMEOUT:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            We’re having trouble signing you in. Please try again shortly, or
            contact <SupportLink /> if this continues and share this error code
            with the request ID.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.LOGIN_TIMEOUT}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.MEMBER_OF_OTHER_WORKSPACE:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak">
            It seems like you’re already a member of another workspace. To
            access Mentimeter, sign in to an existing account.
          </P>
          <P mb={2}>
            If you keep getting this error, contact <SupportLink /> for help and
            share this error code with the request ID.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.MEMBER_OF_OTHER_WORKSPACE}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.MISSING_SAML_RESPONSE:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak">
            Something went wrong when verifying your account. Please try again.
            If you keep getting this error, reach out to your internal IT
            administrator for help.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.MISSING_SAML_RESPONSE}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.USER_ACCOUNT_DEACTIVATED:
      const defaultMessage = `Your account has been deactivated by your workspace administrator. To regain access, contact your internal IT administrator.`;

      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            {error.message ? error.message : defaultMessage}
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.USER_ACCOUNT_DEACTIVATED}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.SERVICE_UNAVAILABLE:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak">
            The service is currently down for maintenance, we apologize for any
            inconvenience. Please try again shortly. If you keep getting this
            error, reach out to <SupportLink /> for help.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.SERVICE_UNAVAILABLE}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.TENANT_RESTRICTED_WORKSPACE:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak">
            You don’t have access rights to this workspace, based on your
            organization’s settings. Please reach out to your internal IT
            administrator for help.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.TENANT_RESTRICTED_WORKSPACE}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.WORKSPACE_LICENSES_EXHAUSTED:
      const contactEmail = error.data?.contact_email;
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            No room is available in this workspace. If you still want to join,
            reach out to the owner
            {contactEmail ? ` at ${contactEmail}` : ' of your workspace'}.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.WORKSPACE_LICENSES_EXHAUSTED}
            requestId={requestId}
          />
        </CalloutCard>
      );
    case ssoErrorCodes.INVALID_CONFIRMATION_TOKEN:
      return (
        <CalloutCard theme="negative">
          <P color="onNegativeWeak" mb={2}>
            Something went wrong. Please check you used the right link when
            confirming your email. If you keep getting this error, contact{' '}
            <SupportLink /> for help and share this error code with the request
            ID.
          </P>
          <SSOErrorMessageDetails
            errorCode={ssoErrorCodes.INVALID_CONFIRMATION_TOKEN}
            requestId={requestId}
          />
        </CalloutCard>
      );
    default:
      return (
        <CalloutCard theme="negative">
          <SSOGenericErrorMessage requestId={requestId} />
        </CalloutCard>
      );
  }
}
